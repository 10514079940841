@import "./shared";

.radioRoot {
	display: flex;
	flex-wrap: wrap;
	gap: 46px;

	.label {
		display: flex;
		align-items: center;
		font-family: var(--font-poppins);
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 16px;
		gap: 14px;

		input {
			display: none;
		}

		svg {
			color: transparent;
			transition: .3s color;
			flex-shrink: 0;
		}

		input:checked ~ svg {
			color: black;
		}
	}
}


.sliderWrapper {
	@include max(1024) {
		margin: 0 16px 8px;
	}
	&.noTooltip {
		:global(.noUi-tooltip) {
			display: none;
		}
	}

	&:hover {
		:global(.noUi-touch-area) {
			transform: scale(1.4);
		}

		:global(.noUi-connect):before {
			transform: scaleY(1.4);
		}
	}

	:global(.noUi-target) {
		background: none;
		box-shadow: none;
		border: none;
	}

	:global(.noUi-horizontal .noUi-tooltip) {
		top: 120%;
		bottom: auto;
		font-size: 12px;
		line-height: 22px;
		letter-spacing: 0.0583333px;
		color: #CFCFCF;
		background: none;
		box-shadow: none;
		border: none;
	}

	:global(div.noUi-connects):before {
		content: "";
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		top: 50%;
		margin-top: -1px;
		background: #423072;
		border-radius: 7px;
	}

	:global(div.noUi-handle) {
		background: none;
		width: 13px;
		height: 13px;
		border-radius: 100%;
		border: none;
		box-shadow: none;
		right: -6px;
		top: 2px;

		&:before {
			position: absolute;
			background-color: transparent;
			width: 20px;
			height: 20px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&:after {
			content: none;
		}
	}

	:global(.noUi-touch-area) {
		background: #CC78FF;
		border-radius: 100%;
		transition: transform .2s;
		transform-origin: center;
	}

	:global(.noUi-connect) {
		height: 2px;
		top: 50%;
		margin-top: -1px;

		&:before {
			content: "";
			background-color: #CC78FF;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transition: transform .2s;
			transform-origin: center;
		}
	}

}

.appear {
	filter: blur(5px);
	transition: .5s;
	transform: translate3d(0, 50px, 1px);

	&.active {
		filter: blur(0);
		transform: translate3d(0, 0, 1px);
	}

	&.delay1 {
		transition-delay: .1s;
	}

	&.delay2 {
		transition-delay: .2s;
	}

	&.delay3 {
		transition-delay: .3s;
	}
}


.chartInjectable {
	max-width: var(--width);
	margin: 30px auto;
	width: calc(100vw - 32px);
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	&.mobile {
		left: 0;
		transform: translateX(0);
		width: auto;

		.bodyWrapper {
			flex-wrap: wrap;

			.chartParent {
				width: 100%;
				max-height: calc(100vw - 50px);
			}
		}

		.bottomContent {
			flex-wrap: wrap;
		}

		.pointsList {
			gap: 16px;
		}

		.right {
			width: 100%;
			margin-left: 0;
			margin-top: 8px;
			margin-bottom: 8px;
		}

		.chartBody {
			padding-right: 16px;
		}

		.bottom {
			padding-left: 0;
		}
	}

	.selectResort {
		width: 100%;
		color: #CFCFCF;

		.labelChip {
			width: 10px;
			height: 10px;
			display: inline-block;
			background-color: var(--bg);
			border-radius: 50%;
			margin-right: 8px;
		}

		:global(.ant-select-selection-item) {
			background-color: #423072;
			border: 1px solid transparent;
		}

		:global(.ant-select-selector) {
			border: 1px solid #CC78FF;
		}

		:global(.ant-select-clear) {
			color: #CC78FF;
			background-color: transparent;
			opacity: .3;
		}

		&:global(.ant-select-focused) {
			:global(.ant-select-clear) {
				opacity: 1;
			}
		}

		:global(.ant-select-selection-item-remove) {
			color: #CC78FF;
		}
	}

	.chartBody {
		padding: 8px 8px 24px 16px
	}

	.bodyWrapper .chartParent {
		width: calc(100% - 210px);
		height: var(--height)
	}

	.right {
		margin-left: 16px;
		width: 200px;
		flex-shrink: 0;
		position: relative;

		.resortsRadio {
			gap: 2px;
			position: absolute;
			overflow: auto;
			inset: 0;
			align-content: start;

			&::-webkit-scrollbar-track {
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 8px;
				background-color: #30254c;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #423072;
			}
		}

		.resortLabel {
			font-weight: 400;
			font-size: 15.7365px;
			line-height: 21px;
			color: #CFCFCF;
			width: 100%;
			cursor: pointer;

			&:has(input:checked) {
				font-weight: 600;
			}

			svg {
				circle {
					&:first-child {
						r: 8;
					}

					&:last-child {
						stroke: transparent;
						stroke-width: 2px;
						r: 5;
					}
				}
			}

			circle:last-child {
				color: #30254C;
				stroke: #30254C;
			}

			input:checked ~ svg circle:last-child {
				color: var(--color);
				stroke: #30254C;
			}

			circle:first-child {
				fill: var(--color);
			}
		}
	}

	.bottom {
		margin-top: 8px;
		padding-left: 20px;
	}

	.bottomContent {
		display: flex;
	}

	.bottomInfo {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 8px;
		display: flex;
		gap: 5px;
	}

	.lastUpdate {
		width: 216px;
		font-family: var(--font-poppins);
		font-size: 10px;
		line-height: 22px;
		letter-spacing: 0.0583333px;
		color: #979797;
		align-self: flex-end;
		text-align: right;
		padding-right: 16px;
		margin-left: auto;
		transform: translateY(12px);
	}

	.description {
		font-size: 16px;
		line-height: 24px;
		color: #7A7A7A;
		margin-top: 7px;
	}
}

.chartInfoPopover {
	@include popoverStyle(#3b3060, 16px);
}

.popContent {
	width: 185px;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.02em;
	color: #CFCFCF;
}

.pointsListContainer {
	position: relative;
	width: 100%;
	height: 32px;

	&:hover {
		.arrow {
			opacity: 1;
		}
	}

	.pointsList {
		display: flex;
		list-style: none;
		overflow: auto;
		gap: 32px;
		max-width: 100%;
		padding: 0;
		margin: 0 32px;
		-ms-overflow-style: none;
		scrollbar-width: none;
		scroll-behavior: smooth;
		position: absolute;
		inset: 0;
		color: #fff;

		&::-webkit-scrollbar {
			display: none;
		}

		.pointItem {
			flex-shrink: 0;
			background: #3B3060;
			border-radius: 6px;
			padding: 5px 11px;
			display: flex;
			align-items: center;
			border: 2px solid transparent;
			font-size: 14px;
			line-height: 14px;
			height: 32px;
			letter-spacing: 0.0583333px;
			cursor: pointer;
		}

		.pointItemActive {
			background: #423072;
			border: 2px solid #CC78FF;
		}
	}

	.arrow {
		cursor: pointer;
		position: absolute;
		right: 100%;
		opacity: .3;
		background-color: transparent;
		border: none;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 6px;
		transition: opacity .2s;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;

		svg {
			height: 100%;
		}

		& + .arrow {
			right: 0;
			left: auto;
			transform: rotate(0.5turn);
		}
	}
}
.starRating {
	display: var(--defaultDisplay, inline-flex);
	margin: 0 auto;
	user-select: none;
	gap: var(--gap);

	.starHolder {
		position: relative;
		font-size: 0;
		line-height: 0;
		height: var(--size);
		width: var(--size);
	}
	.svgFilled {
		height: var(--size);
		width: var(--size);
	}
	.filledWrap {
		position: absolute;
		width: var(--rootPercent,var(--percent));
		left: 0;
		top: 0;
		overflow: hidden;
	}
	.svgEmpty {
		width: var(--size);
		height: var(--size);
	}
}
.shortStars {
	display: var(--shortDisplay, flex);
	gap: 2px;
	color: #fff;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}
