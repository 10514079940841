@import "@css/shared";

.root {
	position: relative;

}
.playBtn {
	position: absolute;
	left: 50%;
	top: 50%;
	transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
	z-index: 2;
	cursor: pointer;
	border: none;
	background-color: transparent;
	transform: translate(-50%, -50%);
	padding: 0;

	img {
		width: 100%;
		height: auto;
	}
	@include max(1024) {
		width: 70px;
	}
}
.videoImg {
	width: 100%;
	height: 360px;
	max-height: 100%;
	cursor: pointer;
	background-color: black;
	object-fit: cover;
	border-radius: 8px;
}


.video {
	width: 100%;
	height: 360px;
	max-height: 100%;
}
