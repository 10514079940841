@use "../mixin" as m;

.root {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;
	padding-top: 22px;
	min-height: 80vh;

	> h1 {
		font-family: var(--font-lora);
		font-size: 64px;
		font-weight: 500;
		line-height: 81.92px;
		letter-spacing: -0.02em;
		margin-bottom: 8px;
	}

	.description {
		font-family: var(--font-poppins);
		font-size: 16px;
		line-height: 22.4px;
		letter-spacing: 0.05833333358168602px;
		min-height: 70px;
		margin-bottom: 30px;
		color: #CFCFCF;
	}

	.navigation {
		color: #CFCFCF;
		font-family: var(--font-poppins);
		font-size: 24px;
		line-height: 24px;
		display: flex;
		gap: 16px;
		max-width: 1150px;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 18px;

		.item {
			position: relative;
			cursor: pointer;
			padding-bottom: 14px;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 4px;
				background: #CC78FF;
				transform: scaleX(0);
				transition: 0.3s;
			}

			&.active {
				color: #fff;

				&::after {
					transform: scaleX(1);
				}
			}
		}
	}

	.filters {
		display: flex;
		align-items: center;
		gap: 14px;
		min-height: 70px;
		flex-wrap: wrap;
		@media (max-width: 1024px) {
			width: 472px;
			max-width: 100%;

		}
	}

	.pointsSlider {
		width: 30%;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		margin-left: 14px;
		padding-bottom: 16px;
		font-family: var(--font-poppins);
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		position: relative;
		height: 66px;
		@media (max-width: 1024px) {
			width: 100%;
			max-width: 452px;
			margin-right: 14px;
		}

		.minValue, .maxValue {
			position: absolute;
			left: 0;
			font-family: var(--font-poppins);
			font-size: 12px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.05833333358168602px;
			text-align: left;
			transform: translateX(-50%);
		}

		.maxValue {
			position: absolute;
			right: 0;
			left: auto;
			transform: translateX(50%);
		}
	}

	.toggleWrapper {
		font-family: var(--font-poppins);
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.05833333358168602px;
		text-align: left;
		display: flex;
		gap: 12px;
		cursor: pointer;
		margin-left: auto;
	}

	.soldToggle {
		input {
			cursor: pointer;
		}

		svg {
			width: 43px;
			height: 18px;
		}
	}

	.select:global(.ant-select) {
		width: 230px;
		@media (max-width: 1024px) {
			width: calc((100% - 14px) / 2);
		}
		@media (max-width: 375px) {
			width: 100%;
		}

		:global(.ant-select-selector) {
			border: none;
			height: 38px;
			background: #3B3060;
			cursor: pointer;
			padding-left: 12px;
			color: #fff;
		}

		&:global(.ant-select-disabled.ant-select) {
			:global(.ant-select-selector) {
				background: #3B3060;
			}
		}

		:global(.ant-select-selection-item) {
			background: transparent;
			border: none;
		}
	}

	.mobileNavigation:global(.ant-select) {
		width: calc(230px * 2 + 14px);
		max-width: 100%;
		display: none;

		:global(.ant-select-selection-item) {
			line-height: 38px;
		}
	}

	.deals {
		--offsetTop: var(--headerHeight);
		min-height: max(300px, 30vh);
		position: relative;
		width: min(1425px, calc(100vw - 16px));
		left: 50%;
		overflow: auto;
		transform: translateX(-50%);
		margin: 16px 0 32px;
		padding-bottom: 50px;
		--columns: 38px 76px 150px 32px 32px 95px 45px 51px 80px 79px 89px 100px;
		@include m.scrollStyle(#cc78ff, #d4c9c924)
	}
	.loadMore {
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		height: 32px;
		&.disabled {
			display: none;
		}
	}

	.dealsNotAuthorized {
		padding: 16px;
		transform: translateX(-50%);
		position: relative;
		width: min(1425px, 100vw);
		left: 50%;

		.mobileImg {
			display: none;
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	.shadow {
		position: fixed;
		left: 50%;
		width: 100vw;
		height: 60vh;
		bottom: 0;
		transform: translateX(-50%);
		background: linear-gradient(180deg, rgba(48, 37, 76, 0) 0%, #30254C 56%);

	}

	.spin {
		position: absolute;
		left: 50%;
		top: 50%;
		padding: 16px;
		transform: translate(-50%, -50%);
	}

	.resortCell {
		text-align: left;
		font-family: var(--font-poppins);
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		a {
			color: #FFFFFF;
			&:visited {
				color: #c278f8;
			}
		}

		.resortId {
			font-size: 11px;
			line-height: 19px;
			text-align: left;
		}

		.resortShort {
			display: none;
		}
	}

	.centerCell {
		text-align: center;
	}

	.gridHeaderWrap {
		display: grid;
		align-items: center;
		gap: var(--gap, 24px);
		grid-template-columns: var(--columns);
		padding: 2px 16px;
		border-radius: 8px;
		justify-content: space-between;
		margin-bottom: 16px;
		line-height: 19px;
		font-family: var(--font-poppins);
		font-size: 14px;
		font-weight: 500;
		position: sticky;
		z-index: 2;
		top: 0;
		background-color: #30254C;
	}

	.gridHeaderWithSort {
		cursor: pointer;
	}

	.gridRow {
		display: grid;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		gap: var(--gap, 24px);
		padding: 8px 16px;
		grid-template-columns: var(--columns);
		border-bottom: 1px solid #30254C;
		cursor: pointer;
	}

	.viewsColumn {
		text-align: left;
		display: flex;
		flex-direction: column;

		svg {
			width: 15px;
			height: auto;
			margin-right: 2px;
		}

		> span {
			display: flex;
			align-items: center;
		}
	}

	.notFound {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		margin-top: 50px;
	}

	.stars {
		--defaultDisplay: flex;
		--shortDisplay: none;
	}

	@media (max-width: 1284px) {
		.gridHeaderWrap {
			width: max-content;
			min-width: 100%;
		}
	}
	@media (max-width: 1024px) {
		.deals {
			--columns: 38px 76px 150px 32px 32px 95px 45px 51px 80px 100px;
			--gap: 16px;
			height: max(400px, calc(100vh - var(--headerHeight) - 100px));
		}
		.hiddenOnMobile {
			display: none;
		}
		.navigation {
			margin-bottom: 0;

			.item {
				display: none;
			}

			.mobileNavigation {
				display: block;
			}
		}
		.dealsNotAuthorized {
			img {
				display: none;
			}

			.mobileImg {
				display: block;
			}
		}
	}
	@media (max-width: 768px) {
		.deals {
			--gap: 8px;
			--columns: 38px 40px 83px 32px 32px 95px 45px 51px 80px 100px;
		}
		.stars {
			--defaultDisplay: none;
			--shortDisplay: flex;
		}
		> h1 {
			font-size: 38px;
			line-height: 1;
			margin-bottom: 16px;
		}
		.description {
			min-height: 0;
			margin-bottom: 16px;
		}
		.select {
			width: 50%;
		}
		.mobileNavigation {
			width: 100%;
		}
		.dealsNotAuthorized {
			padding: 8px;
		}
		.resortCell {
			.resortShort {
				display: block;
			}

			.resortName {
				display: none;
			}
		}
	}
}

.authDialog {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	padding: 8px;

	.backdrop {
		z-index: 0;
		position: absolute;
		inset: 0;
	}

	.container {
		position: relative;
		z-index: 1;
		width: 770px;
		background: #423072;
		max-width: 100%;
		border-radius: 8px;
		padding: 18px 18px 18px 50px;
		@include m.max(768) {
			padding: 18px;
		}

	}

	.content {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		h1 {
			font-family: var(--font-poppins);
			font-size: 40px;
			font-weight: 500;
			line-height: 60px;
			padding-bottom: 8px;
			margin-bottom: 18px;
			border-bottom: 1px solid #CC78FF
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 16px;
		list-style: none;
		margin-bottom: 27px;
		padding-left: 0;

		li {
			display: flex;
			align-items: center;
			gap: 12px;
			font-family: var(--font-poppins);
			font-size: 16px;
			font-weight: 400;
			line-height: 22.4px;

			&:before {
				content: "";
				background: url("/images/check.svg");
				display: inline-block;
				width: 20px;
				height: 20px;
			}
		}

	}

	.toSignUpBtn {
		cursor: pointer;
		padding: 12px 18px;
		box-sizing: border-box;
		border-radius: 8px;
		color: #FFFFFF;
		transition: background-color 0.3s;
		background-color: #9E00FF;
		box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
		border: none;
		text-align: center;
		width: 356px;
		height: 42px;
		font-family: var(--font-poppins);
		font-size: 18px;
		font-weight: 500;
		line-height: 16px;
		max-width: 100%;
		margin-bottom: 8px;
	}

	.naveAccount {
		font-family: var(--font-poppins);
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		cursor: pointer;
		border: none;
		background: transparent;
		width: 356px;
		max-width: 100%;
		height: 42px;

		b {
			color: #CC78FF;
			font-weight: 400;
		}
	}

	.logo {
		position: absolute;
		left: 0;
		top: 10px;
		@include m.max(768) {
			display: none;
		}
	}
}

.viewsTooltip {
	max-width: none!important;
	:global(.ant-tooltip-inner) {
		background-color: rgb(59 48 96);
	}
	:global(.ant-tooltip-arrow-content) {
		--antd-arrow-background-color: rgb(59 48 96);
	}
}

.tooltipContent {
	display: flex;
	padding: 8px;
	gap: 16px;

	h3 {
		margin: 0 0 8px;
		display: flex;
		align-items: center;
		gap: 5px;
	}

	.tooltipRow {
		padding-left: 8px;

		> div {
			display: flex;
			gap: 16px;
		}

		span {
			flex-grow: 1;
			white-space: nowrap;
		}

	}
}
