@import "../shared";

.root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
}

.content {
    padding-top: 27px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: minmax(560px, 1fr) minmax(320px, 550px);
    gap: 16px;
    grid-template-areas:
        "chart pricing"
        "dealScore pricing"
        "pointsSummary historicalDues";

    .chartDescription {
        color: #cfcfcf;
        font-size: 12px;
        letter-spacing: 0.058px;
        margin: 3px auto 0;
        padding: 0 16px;

        a {
            margin-left: 4px;
            color: #cfcfcf;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.058px;
            text-decoration-line: underline;
        }
    }

    .historicalDues {
        margin-top: 60px;
        grid-area: historicalDues;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include max(1024) {
            margin-top: 0;
        }

        h3 {
            color: #fff;
            font-family: var(--font-poppins);
            font-size: 23.605px;
            font-style: normal;
            font-weight: 400;
            line-height: 130.023%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    > .pointsAvailable {
        display: none;
        grid-area: pointsAvailable;
    }

    .historicalDuesNav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 8px;
        margin-top: 8px;
    }

    $stroke: 1px;
    $transition_duration: 100ms;
    $pipeline_width: 80px + $stroke;
    $pipeline_height: 34px + $stroke;
    $pipes: 4;

    @function border-pipeline($transition_duration, $pipe) {
        @return $transition_duration * $pipe;
    }

    @mixin border-animation($dimension, $easing, $pipe) {
        transition: $transition_duration $dimension $easing border-pipeline($transition_duration, $pipe);
    }

    .historicalDuesNext,
    .historicalDuesPrev {
        position: relative;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 80px;
        font-weight: normal;
        transition: 0.3s;
        &:before {
            position: absolute;
            width: 0;
            height: $stroke;
            content: "";
            background: #cc78ff;
            top: -$stroke;
            left: -$stroke;
            @include border-animation(width, ease, 3);
        }
        &:after {
            position: absolute;
            width: $stroke;
            height: 0;
            content: "";
            background: #cc78ff;
            top: -$stroke;
            right: -$stroke;
            @include border-animation(height, ease, 2);
        }
        .boxInner:after {
            position: absolute;
            width: 0;
            height: $stroke;
            content: "";
            background: #cc78ff;
            bottom: -$stroke;
            right: -$stroke;
            @include border-animation(width, ease, 1);
        }
        .boxInner:before {
            position: absolute;
            width: $stroke;
            height: 0;
            content: "";
            background: #cc78ff;
            bottom: -$stroke;
            left: -$stroke;
            @include border-animation(height, ease, 0);
        }
        &:hover:not(:disabled) {
            border-color: transparent;
            color: #cc78ff;
            &:before {
                width: $pipeline_width;
                @include border-animation(width, ease, 0);
            }
            &:after {
                height: $pipeline_height;
                @include border-animation(height, ease, 1);
            }
            .boxInner:after {
                width: $pipeline_width;
                @include border-animation(width, ease, 2);
            }
            .boxInner:before {
                height: $pipeline_height;
                @include border-animation(height, ease, 3);
            }
        }
        &:disabled {
            color: rgba(255, 255, 255, 0.61);
            cursor: default;
        }
        svg {
            position: absolute;
            top: 50%;
            transform-origin: center;
            path {
                fill: currentColor;
            }
        }
    }
    .historicalDuesPrev {
        padding: 6px 8px 6px 40px;

        svg {
            left: 15px;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    .historicalDuesNext {
        padding: 6px 40px 6px 8px;

        svg {
            right: 15px;
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    @include max(1024) {
        grid-template-columns: minmax(280px, 700px);
        grid-template-rows: none;
        grid-template-areas: "pricing" "pointsAvailable" "dealScore" "chart" "pointsSummary" "historicalDues";
        justify-content: center;

        .pointsAvailable {
            margin-top: 0;
            margin-bottom: 27px;

            h2 {
                margin-bottom: 16px;
            }

            > div {
                gap: 14px;
            }
        }
    }

    .chart {
        grid-area: chart;
    }

    @include max(1300) {
        .notActiveListing {
            margin-right: 0!important;
        }
        .dealScore {
            grid-template-areas: "left" "right"!important;
            grid-template-columns: minmax(320px, 534px);
            grid-template-rows: none;
            grid-auto-columns: unset;
            justify-items: center;

            .dealScoreLeft {
                margin-bottom: 18px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                > p {
                    max-width: none;
                }
            }

            .dealScoreRight {
                min-width: 300px;
                align-self: center;
            }
        }
    }
    @include max(1200) {
        .dealScore {
            padding: 18px;
        }
    }
    @include max(1024) {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
        gap: 0;
        > div, > section {
            margin: 0 0 24px;
        }
    }
    @include max(550) {
        .dealScore {
            grid-template-columns: 1fr;

            .dealScoreRight {
                width: 100%;
                min-width: auto;
            }
            .dealScoreLeft {
                width: 100%;
                .dealScoreText {
                    max-width: 100%;
                }
            }
        }
    }
    @include max(374) {
        .dealScore {
            padding: 8px;
            .dealScoreLeft {
                > p {
                    max-width: none;
                }
            }
        }
    }
}

.dealScore {
    grid-area: dealScore;
    display: grid;
    grid-template-areas:
            "left right"
            "scoreDialogBtn right";
    grid-auto-columns: 1fr minmax(290px, auto);
    padding: 26px 36px;
    border-radius: 8px;
    background: #30254c;

    &.notActive {
        grid-template-areas:
            "left right"
            "left right";
        .dealScoreLeft {
            display: flex;
            flex-direction: column;
        }
    }

    .dealScoreText {
        max-width: 300px;
        margin-top: 17px;
        margin-bottom: 8px;
        @include max(1300) {
            margin: auto;
        }
        a {
            color: rgba(254, 90, 159, 1);
        }
    }
    .notActiveListing {
        background: #3D3261;
        flex-grow: 1;
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
        margin-right: 35px;
    }

    .dealScoreStats {
        display: flex;
        flex-direction: column;
        background: rgba(61, 50, 97, 1);
        border-radius: 8px;
        padding: 8px;
        margin-top: 4px;
        gap: 7px;

        .dealScoreStatsItem {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.05833333358168602px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            color: #FFFFFF;
            > span {
                color: rgba(207, 207, 207, 1);
            }
        }
    }

    .dealScoreLeft {
        grid-area: left;
    }
    .starRating {
        margin: 0;
    }

    .dealScoreRight {
        grid-area: right;
        padding: 12px;
        border-radius: 8px;
        background: #3c3160;
        margin-bottom: auto;
        margin-top: auto;
    }
}
.dealScoreTableTitle {
    display: flex;
    white-space: nowrap;
    flex-grow: 1;
    align-items: center;
    color: #cdcdcd;
    font-family: var(--font-poppins);
    font-size: 12.137px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.701px; /* 220% */
    letter-spacing: 0.071px;
    gap: 8px;

    &:after {
        content: "";
        display: block;
        height: 1px;
        width: auto;
        flex-grow: 1;
        background: linear-gradient(90deg, #9e00ff 9.78%, rgba(158, 0, 255, 0) 87.17%);
    }
}
.dealScoreTable {
    display: grid;
    grid-auto-columns: auto auto auto auto 92px;
    position: relative;
    row-gap: 4px;

    .gridLink {
        position: absolute;
        left: 0;
        width: 100%;
        line-height: 18px;
        z-index: 1;
        text-indent: -9999px;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color 0.3s;
        height: 27px;

        &:hover {
            border-color: #00ffd1;
        }
    }

    .gridRow1Holder {
        position: absolute;
        border-radius: 8px;
        background: #30254c;
        box-shadow: 0 13.162px 38.545px 0 rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 18px;
    }

    .gridRow1 {
        z-index: 1;
        position: relative;
        color: #c9c9c9;
        font-size: 13.162px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.055px;
    }

    .gridSpacer {
        grid-column: 1/6;
        height: 6px;
    }

    .gridSeparatorTop {
        margin-top: 12px;
        grid-column: 1 / 6;
    }
    .gridSeparatorBottom {
        margin-top: 2px;
        grid-column: 1 / 6;
    }

    .gridRow {
        color: #cbcbcb;
        line-height: 1;
        font-size: 13px;
        padding-top: 2px;
        padding-bottom: 2px;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gridCellCurrent {
        &:before {
            content: "This contract";
            z-index: 2;
            position: absolute;
            left: 0;
            background-color: #3c3160;
            transform: translate(10px, -16px);
            font-size: 12px;
            color: #00ffd1;
            padding: 4px;
        }
        &:after {
            content: "";
            position: absolute;
            border: 1px solid #00ffd1;
            border-radius: 8px;
            left: 0;
            width: 100%;
            height: 27px;
        }
    }

    .gridArrow {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        justify-content: center;
    }

    .gridColl1 {
        grid-column: 1;
        text-align: center;
    }

    .gridColl2 {
        grid-column: 2;
        text-align: center;
    }

    .gridColl3 {
        grid-column: 3;
        text-align: center;
    }

    .gridColl4 {
        grid-column: 4;
        text-align: center;
    }

    .gridColl5 {
        grid-column: 5;
        text-align: center;
    }
}

.id {
    font-family: var(--font-lora);
    font-weight: 500;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.47px;
    color: #ffffff;
    padding-left: 8px;
    padding-right: 18px;
    white-space: nowrap;
    order: 0;

    @include max(1024) {
        font-size: 24px;
        line-height: 31px;
        padding-left: 0;
        padding-right: 20px;
    }
}

.row1 {
    display: flex;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    gap: 18px;
    .statusWrap {
        font-size: 0;
        flex-grow: 1;
        display: flex;
        gap: 4px;
    }

    @include max(1024) {
        flex-wrap: wrap;
        margin-top: 16px;
        gap: 8px;
    }
    @include max(768) {
        display: grid;
        grid-template-columns: 20px repeat(9, auto);
        grid-template-rows: [row] auto [row];
        .linkBack {
            grid-row: 1;
            width: 25px;
        }
        .pageTitle {
            order: 6;
        }
        .id {
            width: auto;
            font-size: 19px;
        }
        .h1 {
            grid-row: 1;
            flex-direction: row;
            align-items: center;
            grid-column: 2 / 11;
        }
        .statusWrap {
            grid-row: 2;
            grid-column: 1 / 10;
        }
        .likeBtn {
            grid-row: 2;
            grid-column-end: 11;
        }
    }
}

.linkBack {
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    font-size: 0;

    @include max(1024) {
        margin-left: -7px;
    }
}

.left {
    width: 714px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    gap: 16px;
}

h1.h1 {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pageTitle {
    font-family: var(--font-lora);
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.47px;
    color: #cc78ff;
    margin: 0;
    order: 1;

    @include max(991) {
        margin-left: 0;
    }
}

.pointsAvailable {
    width: 100%;
    margin-bottom: 16px;

    @include max(991) {
        width: 100%;
        margin: 24px auto 0;

        .item {
            margin-left: 0;
            min-width: 223px;
        }

        .overItems {
            gap: 10px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    h2 {
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        font-weight: normal;
    }

    .overItems {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .item {
        min-width: 33.333%;
        display: flex;
        background: #3b305f;
        border-radius: 6px;
        padding: 5px 11px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.0583333px;
        color: #cfcfcf;

        span {
            flex-shrink: 0;
            margin-right: 8px;
        }

        .points {
            flex-shrink: 0;
            background-color: #9e00ff;
            color: #ffffff;
            border-radius: 3px;
            margin-left: auto;
            padding: 0 7px;
        }
    }
}

.right {
    width: 551px;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @include max(1200) {
        width: 400px;
        min-width: 300px;
    }
}

.likeBtn {
    @extend .btnTransparent;
    padding: 14px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    margin-left: auto;

    svg {
        margin-left: 10px;
    }

    .heartIconActive path {
        fill: #cc78ff;
    }

    @include max(1200) {
        border-color: transparent;
        display: inline-flex;
        align-items: center;
        padding: 10px 12px;
        justify-content: center;
        margin-left: auto;

        span {
            display: none;
        }

        svg {
            margin-left: 0;
        }
    }
}

.row {
    width: 100%;
}

.overMarketReport {
    padding: 20px;
    border: 1px solid black;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.howDoWeCalculateTooltip {
    max-width: none;

    img {
        max-width: 100%;
        @include max(600) {
            height: auto;
        }
    }

    hr {
        background-color: rgba(59, 48, 96, 1);
        height: 1px;
        border: none;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    :global(.ant-tooltip-inner) {
        min-width: 320px;
        width: 600px;
        max-width: calc(100vw - 16px);
        display: flex;
        flex-direction: column;
        padding: 16px;
        color: #cfcfcf;
        font-size: 12.23px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.051px;
    }

    :global(.ant-tooltip-arrow-content),
    :global(.ant-tooltip-inner) {
        background-color: #30254c;
        --antd-arrow-background-color: #30254c;
    }

    .goToResortChart {
        display: block;
        align-self: center;
        margin: 16px auto;
        text-align: center;
        border-radius: 6.988px;
        border: 1.747px solid #cc78ff;
        box-shadow: 0 13.10341px 35.816px 0 rgba(0, 0, 0, 0.18);
        width: 218.39px;
        height: 54.086px;
        padding: 15.724px 0;
        justify-content: center;
        align-items: center;
        gap: 8.736px;
        flex-shrink: 0;
        color: #fff;
        font-size: 15.724px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.437px;
    }
}

.pointsSummary {
    padding: 31px 45px;
    background: #30254c;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #cfcfcf;
    gap: 12px;
    margin-top: 60px;
    grid-area: pointsSummary;

    .howDoWeCalculate {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-right: auto;

        @include max(600) {
            width: 100%;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .pointsSummaryHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        min-height: 57px;
    }

    h2 {
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 0.0764815px;
        color: #ffffff;
        margin-bottom: 0;
    }

    .pointsSummarySkeleton {
        margin: auto 0;
    }

    table {
        margin: auto 0 auto -10px;
        width: calc(100% + 20px);
        border-collapse: separate;
        border-spacing: 10px;
        display: block;

        tbody {
            display: table;
            width: 100%;
            border-collapse: separate;
            border-spacing: 10px;
        }
    }

    .hiddenTableHeader {
        position: absolute;
        right: 9000px;
        height: 0;
        font-size: 0;
        line-height: 0;
    }

    .pointsSummaryEmpty {
        opacity: 0.3;
    }

    .pointsSummaryBtn {
        @extend .btnTransparent;
        height: 42px;
        padding: 0 16px;
        line-height: 1;
        display: flex;
        align-items: center;

        svg {
            margin-left: 16px;
        }

        @include max(991) {
            padding-right: 8px;

            svg {
                margin-left: 8px;
            }
        }
    }

    .pointsSummaryNights {
        padding-left: 8px;
        width: 166px;
    }

    td {
        font-family: var(--font-poppins);
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.0764815px;
        color: #ffffff;
    }

    .coll1 {
        width: 100px;
        white-space: nowrap;
    }

    @include max(1200) {
        padding: 18px;
    }

    @include max(992) {
        // max-width: 390px;
        width: 100%;
        min-height: auto;
        margin: 24px auto;

        h2 {
            font-size: 18px;
            line-height: 26px;
            font-weight: normal;
        }

        td {
            font-size: 12px;
            width: 100%;
        }

        .pointsSummaryNights {
            text-align: right;
            white-space: nowrap;
            padding-left: 0;
        }

        .coll1 {
            width: 70px;
        }
    }
}

.pointsSummaryControls {
    letter-spacing: 0.0583333px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 37px;
    width: calc(100% - 230px);
    flex-grow: 1;
    min-width: 280px;

    .control {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        color: #cd7afe;
        cursor: pointer;

        svg {
            margin-left: 4px;
        }
    }

    .controlsLevel2 {
        margin-top: 8px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.058px;
    }

    @include max(991) {
        font-size: 18px;
        line-height: 20px;
    }
}

.pointsSummaryControlsPopover:global(.ant-popover) {
    :global(.ant-popover-inner) {
        background-color: #2b2741;

        input {
            background-color: #3b3060;
            border: 1px solid transparent;
            border-radius: 8px;
            font-size: 12px;
            line-height: 18px;
            text-align: justify;
            color: #cfcfcf;
            height: 52px;
            padding: 16px;
            box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
            outline: none;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }

            &:focus {
                border-color: #cc78ff;
                color: #ffffff;
            }
        }
    }

    :global(.ant-popover-title) {
        border-color: #cc79ff;
        color: #ffffff;
    }

    :global(.ant-popover-arrow) {
        border-color: #2b2741;
    }
}

.pointsSummaryControlsDropDown {
    :global(.ant-dropdown-arrow) {
        border-color: #2b2741;
    }

    :global(.ant-dropdown-menu) {
        max-height: 250px;
        overflow-y: auto;
        @extend .menu;
    }
}

.progress {
    width: 100%;
    background: #271e42;
    border-radius: 24.9111px;
    height: 11px;
    position: relative;
    overflow: hidden;

    @include max(991) {
        height: 6px;
    }

    .progressBar {
        background: #cc78ff;
        border-radius: 24.9111px;
        height: 100%;
        width: 100%;
        transform: scaleX(0);
        transition: 0.3s;
        transform-origin: left;
        right: 100%;
        position: absolute;
    }
}

.whatAreYouContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    flex-wrap: wrap;

    @include max(991) {
        max-width: 390px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;

        .whatAreYou {
            font-weight: 500;
            font-size: 18px;
            line-height: 31px;

            p {
                color: #cfcfcf;
            }
        }
    }

    .whatAreYou {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: #ffffff;
        max-width: 330px;

        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.savingsRoot {
    text-align: center;
    position: relative;

    @include max(991) {
        width: 100%;
        margin-right: 0;
        text-align: center;

        .topText {
            font-size: 18px;
            line-height: 46px;
            margin-top: 24px;
        }
    }

    /*&:before {
		content: "";
		left: 0;
		top: 0;
		margin: auto;
		bottom: 0;
		right: 0;
		width: 150px;
		height: 140px;
		border-radius: 100%;
		position: absolute;
		background: #F0FBC7;
		// filter: blur(366.036px);
	}*/

    .topText {
        font-size: 24px;
        line-height: 46px;
        letter-spacing: 0.704783px;
        color: #b964fb;
        position: relative;
        z-index: 1;
    }

    .bottomText {
        font-weight: 500;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.704783px;
        color: #e0e0e0;
        margin-top: 3px;
        z-index: 1;
        position: relative;
    }
}

.resort {
    width: 100%;
}

.seasonsPopoverContent {
    width: 460px;
    color: #ffffff;
    max-width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @include max(991) {
        width: 320px;
    }

    .title {
        width: 100%;
        color: #c278f8;
        margin-bottom: 8px;
        padding-bottom: 4px;
        border-bottom: 1px solid #9c00fc;
    }

    .item {
        width: calc(33.3333333% - 8px);
        display: flex;
        flex-direction: column;
        background-color: #3b3060;
        padding: 4px 11px;
        margin-bottom: 8px;
        min-height: 130px;
        cursor: pointer;
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
            transform: scale(1.15);
            box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
        }

        &[data-selected="true"] {
            background-color: rgba(204, 120, 255, 0.2);

            .title {
                color: #ffffff;
            }
        }

        @include max(991) {
            width: calc(50% - 8px);
        }
    }

    .subItem {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.pointsSummaryPopover:global(.ant-popover) {
    :global(.ant-popover-inner-content) {
        padding: 16px 10px 8px;
    }

    :global(.ant-popover-arrow) {
        border-color: #30254c;
    }

    :global(.ant-popover-inner) {
        background-color: #30254c;
        border-radius: 8px;
    }
}

.resortGuid {
    display: flex;
    align-items: center;
    font-size: 40px;
    line-height: 19px;
    letter-spacing: 0.0583333px;
    font-family: var(--font-lora);
    margin-bottom: 86px;
    margin-top: 50px;

    .line {
        flex-grow: 1;
        margin-left: 48px;
        height: 2px;
        background: linear-gradient(90deg, #9e00ff 9.78%, rgba(158, 0, 255, 0) 87.17%);
    }

    @include max(1200) {
        font-size: 23.6048px;
        line-height: 31px;
        margin-top: 0;
        margin-bottom: 50px;

        .line {
            margin-left: 24px;
        }
    }
}

.linearChart {
    background-color: #30254c;
    border-radius: 8px;
    padding: 10px 18px;
    grid-area: chart;

    .linearChartHead {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .titleWrap {
            display: flex;
            flex-direction: column;
        }

        .title {
            font-family: var(--font-poppins);
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            color: #ffffff;
            margin-bottom: 0;
        }

        .subTitle {
            background: #423072;
            box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            padding: 0 4px;
            margin-top: 3px;
            margin-bottom: 0;
            font-family: var(--font-poppins);
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 22px;
            letter-spacing: 0.0583333px;
            color: #cdcdcd;
            margin-right: auto;
        }

        .labels {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 52px;
            padding-top: 8px;
        }

        .label {
            font-family: var(--font-poppins);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            transition: color 0.3s;
            user-select: none;
            color: #9d9a9a;

            @include max(768) {
                line-height: 21px;
                font-size: 14px;
            }

            &.active {
                color: #cfcfcf;

                span {
                    opacity: 1;
                }
            }

            span {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                opacity: 0.3;
                transition: opacity 0.3s;
            }
        }

        @include max(560) {
            flex-wrap: wrap;
            .title {
                width: 100%;
            }
            .labels {
                gap: 16px;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .direct {
        background-color: #cc78ff;
    }

    .avg {
        background-color: #ff7e7e;
    }

    .thisContract {
        background-color: #00ffd1;
    }

    canvas {
        max-height: 100%;
        position: absolute;
        inset: 0;
    }

    .bodyWrapper {
        display: flex;
    }

    .chartParent {
        width: 100%;
        height: 250px;
        position: relative;

        @include max(500) {
            height: 180px;
        }
    }
    @include max(374) {
        padding: 10px;
    }
}
