@import "../mixin";
@import "../shared";

.saveSearchBtnModal:global(.ant-modal) {
	@include max(768) {
		max-width: calc(100% - 36px);
	}
	:global(.ant-modal-content) {
		padding: 45px 85px;
		background: #31274D;
		box-shadow: 0 4px 88px rgba(0, 0, 0, 0.23);
		border-radius: 8px;
		@include max(768) {
			padding: 24px;
		}
	}

	:global(.ant-modal-close) {
		top: 10px;
	}

	:global(.ant-modal-header) {
		background-color: transparent;
		border: none;
		color: #FFFFFF;
		padding: 0 0 25px;
	}

	:global(.ant-modal-title) {
		color: #FFFFFF;
		font-size: 24px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.05px;
		@include max(768) {
			font-size: 18px;
		}
	}

	:global(.ant-modal-body) {
		padding: 0;
	}

	.saveSearchModalInput {
		background: #3B3060;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		border: none;
		height: 52px;
		padding: 14px 32px;
		font-size: 16px;
		color: #FFFFFF;
		margin-bottom: 33px;

		&::placeholder {
			color: #CFCFCF;
			font-size: 14px;
		}
	}

	.toggleElement {
		svg {
			height: 17px;
			width: 40px;
			:global(.holder) {
				fill: #3B3060;
				transition: .3s;
			}
			:global(.switch) {
				transition: .3s;
				fill: #CC78FF;
			}
		}
		input:checked + svg {
			:global(.holder) {
				fill: #CC78FF;
			}
			:global(.switch) {
				fill: #3B3060;
			}
		}
	}
	.notificationShade {
		pointer-events: none;
		opacity: .3;
	}

	.notificationDesc {
		color: #7D7D7D;
		font-size: 12px;
		font-weight: 500;
		line-height: 15px;
		letter-spacing: 0;
		text-align: left;
		margin-top: 22px;
	}

	.toggleLabel {
		display: flex;
		gap: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.05833333358168602px;
		color: #CC78FF;
		margin-bottom: 14px;
	}

	.saveSearchBtn {
		@extend .btnFilled;
		height: 42px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		margin-left: 16px;
	}

	.notificationRadioRoot {
		display: flex;
		flex-direction: column;
		gap: 14px;

		circle:nth-child(2) {
			r: 7.5;
		}
		circle:nth-child(1) {
			fill: transparent;
			stroke: #CC78FF;
			stroke-width: 2;
			r: 8;
		}
		.notificationRadioLabel {
			color: #CFCFCF;
			font-size: 12px;
			font-weight: 300;
			line-height: 14px;
			letter-spacing: 0;
			text-align: left;
			strong {
				font-size: 14px;
				color: #FFFFFF;
			}

			input:checked ~ svg {
				color: #8A00DF;
			}
		}
	}

	.saveSearchModalFooter {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 8px;
		> button {
			margin-left: 20px;
		}

		@include max(768) {
			margin-top: 39px;
		}

		@include max(512) {
			flex-wrap: wrap;
			gap: 16px;
			button {
				margin-left: 0;
				flex-grow: 1;
				text-align: center;
				justify-content: center;
			}
		}
	}
	.saveSearchBtn {
		@extend .btnFilled;
		height: 42px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		margin-left: 0;
		order: 3;
		display: inline-flex;
		align-items: center;
		gap: 8px;
	}

	.saveSearchBtnCancel {
		@extend .btnTransparent;
		padding: 12px;
		height: 42px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}
}
